export default class IndexCalculator {
  xPositions: Array<number>

  yPositions: Array<number>

  constructor({ xPositions, yPositions }: { xPositions: number[]; yPositions: number[] }) {
    this.xPositions = xPositions
    this.yPositions = yPositions
  }

  setPositions({ xPositions, yPositions }: { xPositions: number[]; yPositions: number[] }) {
    this.xPositions = xPositions
    this.yPositions = yPositions
  }

  fromX(x: number) {
    return IndexCalculator.findIndex(this.xPositions, x)
  }

  fromY(y: number) {
    return IndexCalculator.findIndex(this.yPositions, y)
  }

  private static findIndex(positions: Array<number>, currentPos: number) {
    return positions.findIndex((pos, i) => {
      if (i === 0) {
        return currentPos <= positions[i + 1]
      }
      if (i !== positions.length - 1) {
        return pos <= currentPos && currentPos <= positions[i + 1]
      }
      return true
    })
  }
}
