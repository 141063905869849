import { createTheme } from '@mui/material/styles'

export const theme = createTheme({
  palette: {
    mode: 'dark',
  },
  typography: {
    fontFamily: ['DotGothic16', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
  },
})
