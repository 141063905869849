import React from 'react'

import { isMobile } from 'react-device-detect'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Button from '@mui/material/Button'
import ShareIcon from '@mui/icons-material/Share'
import DialogTitle from '@mui/material/DialogTitle'
import Dialog from '@mui/material/Dialog'

import { siteConfig as site } from 'utils/site'
import { defaultSettings, SettingsContext as SettingsContextType } from 'utils/emoxel'
import Canvas from './Canvas'
import Palette from './Palette'
import Settings from './Settings'

export const SettingsContext = React.createContext({} as SettingsContextType)

const canShare = navigator.share !== undefined || navigator.clipboard !== undefined

export default function Emoxel() {
  const [settings, setSettings] = React.useState(defaultSettings)

  const generateTextValue = () => settings.pixels.map((line) => line.map((pixel) => pixel.value).join('')).join('\n')

  const [textValue, setTextValue] = React.useState(generateTextValue())
  const [dialogOpen, setDialogOpen] = React.useState(false)

  const handleShareButtonClick = async () => {
    const newTextValue = generateTextValue()
    setTextValue(newTextValue)

    const shareText = [newTextValue, site.url].join('\n')
    if (isMobile) {
      await window.navigator.share({
        title: site.title,
        // url: site.url,
        text: shareText,
      })
    } else {
      await navigator.clipboard.writeText(shareText)
      setDialogOpen(true)
    }
  }
  return (
    <Box>
      <Container sx={{ padding: 0 }}>
        {/* eslint-disable-next-line react/jsx-no-constructed-context-values */}
        <SettingsContext.Provider value={{ settings, setSettings }}>
          <Canvas />
          <Palette />
          <Settings />
          {canShare && (
            <Box sx={{ margin: '20px' }} textAlign="center">
              <Button
                sx={{ width: '75%' }}
                variant="outlined"
                startIcon={<ShareIcon />}
                onClick={handleShareButtonClick}
              >
                SHARE
              </Button>
              <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
                <DialogTitle>Copied to clipboard.</DialogTitle>
                <Box sx={{ textAlign: 'center', whiteSpace: 'pre-wrap', padding: '15px' }}>{textValue}</Box>
              </Dialog>
            </Box>
          )}
        </SettingsContext.Provider>
      </Container>
    </Box>
  )
}
