import * as React from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import Modal from '@mui/material/Modal'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import FaceRetouchingNaturalIcon from '@mui/icons-material/FaceRetouchingNatural'
// import MenuIcon from '@mui/icons-material/Menu'
import InfoIcon from '@mui/icons-material/Info'

import Twemoji from 'components/typography/Twemoji'
import LogoSvg from 'assets/logo.svg'
import ScreenshotPng from 'assets/images/screenshot.png'
import { InfoModal } from 'utils/localStorage'

export default function Header() {
  const [infoModalOpen, setInfoModalOpen] = React.useState(false)

  const modalEventHandler = React.useMemo(
    () => ({
      open: () => {
        setInfoModalOpen(true)
      },
      close: () => {
        InfoModal.setIsDisplayedFirst(true)
        setInfoModalOpen(false)
      },
    }),
    [setInfoModalOpen]
  )

  React.useEffect(() => {
    if (InfoModal.getIsDisplayedFirst()) {
      modalEventHandler.open()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1, display: 'flex' }}>
            {/* <IconButton size="large" edge="end" color="inherit" aria-label="menu">
              <MenuIcon />
            </IconButton> */}
            <Box sx={{ width: 48, height: 48 }} />
          </Box>
          <Box sx={{ flexGrow: 1, display: 'flex' }}>
            <img src={LogoSvg} alt="emoxel" width="100px" />
          </Box>
          <Box sx={{ flexGrow: 0 }}>
            <IconButton
              size="large"
              edge="end"
              color="inherit"
              aria-label="info_modal_button"
              sx={{ mr: 0 }}
              onClick={modalEventHandler.open}
            >
              <InfoIcon />
            </IconButton>
            <Modal
              sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
              open={infoModalOpen}
              onClose={modalEventHandler.close}
              aria-labelledby="info_modal_title"
              aria-describedby="info_modal_desc"
              disableAutoFocus
              disablePortal
              keepMounted
            >
              <Box
                sx={{
                  bgcolor: 'background.paper',
                  maxWidth: '500px',
                  maxHeight: '750px',
                  borderRadius: '3px',
                  boxShadow: 24,
                  padding: '24px',
                  mb: 3,
                  overflow: 'auto',
                }}
              >
                <Typography id="info_modal_title" variant="h5" component="h1" sx={{ textAlign: 'center', mb: 3 }}>
                  emoxel
                </Typography>
                <Typography id="info_modal_desc" variant="subtitle1" component="p" sx={{ textAlign: 'center' }}>
                  emoxel = emoji
                  <Twemoji emoji="😀" /> + pixel
                  <Twemoji emoji="⬜" />
                </Typography>
                <Box sx={{ mt: 2 }}>
                  <figure style={{ margin: 15 }}>
                    <img src={ScreenshotPng} alt="screenshot" width="100%" />
                    <Typography
                      variant="caption"
                      component="figcaption"
                      sx={{
                        whiteSpace: 'nowrap',
                        overflowX: 'auto',
                        lineHeight: 2,
                      }}
                    >
                      &quot;Twemoji&quot; by Copyright 2019 Twitter, Inc and other contributors (Licensed under{' '}
                      <Link href=" https://creativecommons.org/licenses/by/4.0/" target="_blank" rel="noreferrer">
                        CC-BY 4.0
                      </Link>
                      )
                    </Typography>
                  </figure>
                </Box>
                <Box sx={{ mt: 4 }}>
                  <Typography variant="body2" component="p" sx={{ mb: 2 }}>
                    This application allows you to draw pixel art using{' '}
                    <Link href="https://twemoji.twitter.com/" target="_blank" rel="noreferrer">
                      Twemoji
                    </Link>
                    (Twitter&apos;s emoji).
                  </Typography>
                  <Typography variant="body2" component="p" sx={{ mb: 2 }}>
                    You can draw pixel art of up to 121 pixels and share it on Twitter.
                  </Typography>
                  <Typography variant="body2" component="p" sx={{ mb: 2 }}>
                    Note: Of course, it is also possible to share it anywhere you like other than Twitter, but please
                    note that emoji may look different depending on your environment.
                  </Typography>
                </Box>
                <Container sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <Box>
                    <Button
                      variant="outlined"
                      startIcon={<FaceRetouchingNaturalIcon />}
                      onClick={modalEventHandler.close}
                    >
                      <Typography variant="body2">START DRAWING</Typography>
                    </Button>
                  </Box>
                </Container>
              </Box>
            </Modal>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  )
}
