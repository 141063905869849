import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'

export default function Footer() {
  return (
    <Box
      sx={{
        marginTop: 'auto',
        padding: 1,
        marginBottom: 1,
        textAlign: 'center',
      }}
    >
      <Typography variant="body2" component="p">
        Created by{' '}
        <Link href="https://github.com/realinternetman" target="_blank" rel="noreferrer">
          realinternetman
        </Link>
      </Typography>
    </Box>
  )
}
