import React from 'react'

import Box from '@mui/material/Box'
import { Stage, Layer, Image } from 'react-konva'
import Konva from 'konva'

import { toTwemojiUrl } from 'utils/emoji'

import { calcPositions, addHistory, textToPixels, movePixels, ModeType, PixelData } from 'utils/emoxel'
import IndexCalculator from 'utils/IndexCalculator'
import { SettingsContext } from './Emoxel'

function Pixel({ data, pixelSize }: { data: PixelData; pixelSize: number }) {
  const image = new window.Image()
  image.src = toTwemojiUrl(data.value)
  return <Image x={data.position.x} y={data.position.y} image={image} width={pixelSize} height={pixelSize} />
}

export default function Canvas() {
  const { settings, setSettings } = React.useContext(SettingsContext)

  const rootRef = React.useRef<HTMLElement>(null)

  // const [windowWidth, setWindowWidth] = React.useState(window.innerWidth)
  // window.addEventListener('resize', () => {
  //   setWindowWidth(window.innerWidth)
  // })

  // Size calculation
  const initialPixelSize = React.useRef<number | null>(null)
  const pixelSize = React.useRef(0)
  const pixelSpace = React.useRef(3)
  const calcPixelSize = (width: number) => {
    const rootElmWidth = (rootRef.current as HTMLElement).clientWidth
    const spacesWidth = (width + 1) * pixelSpace.current
    const pixelsWidth = rootElmWidth - spacesWidth - ((rootElmWidth - spacesWidth) % width)
    const newPixelSize = pixelsWidth / width
    if (initialPixelSize.current && initialPixelSize.current < newPixelSize) {
      return initialPixelSize.current
    }
    return newPixelSize
  }

  const calcCanvasSize = (size: number, widthOrHeight: number) =>
    size * widthOrHeight + (widthOrHeight + 1) * pixelSpace.current

  const canvasWidth = React.useMemo(
    () => calcCanvasSize(settings.pixelSize, settings.width),
    [settings.pixelSize, settings.width]
  )
  const canvasHeight = React.useMemo(
    () => calcCanvasSize(settings.pixelSize, settings.height),
    [settings.pixelSize, settings.height]
  )

  const xPositions = React.useRef(calcPositions(settings.width, settings.pixelSize, pixelSpace.current))
  const yPositions = React.useRef(calcPositions(settings.height, settings.pixelSize, pixelSpace.current))
  const indexCalculator = React.useRef(
    new IndexCalculator({ xPositions: xPositions.current, yPositions: yPositions.current })
  )

  const [canvasIsUpdated, setCanvasIsUpdated] = React.useState(false)

  const generatePixels = React.useCallback((currentPixels: PixelData[][]) => {
    const getNewPixel = ({ xIndex, yIndex }: { xIndex: number; yIndex: number }) => {
      const value =
        currentPixels[yIndex] !== undefined && currentPixels[yIndex][xIndex] !== undefined
          ? currentPixels[yIndex][xIndex].value
          : '⬜'
      return {
        value,
        position: { x: xPositions.current[xIndex], y: yPositions.current[yIndex] },
      }
    }
    return yPositions.current.map((x, yIndex) =>
      xPositions.current.map((y, xIndex): PixelData => getNewPixel({ xIndex, yIndex }))
    )
  }, [])

  // Mode handling
  const mode = React.useRef<ModeType>(settings.mode)
  React.useEffect(() => {
    mode.current = settings.mode
  }, [settings.mode])

  // Regenarate pixels when width or height has been updated
  React.useEffect(() => {
    if (mode.current !== ModeType.RESIZE) {
      return
    }
    pixelSize.current = calcPixelSize(settings.width)
    xPositions.current = calcPositions(settings.width, pixelSize.current, pixelSpace.current)
    yPositions.current = calcPositions(settings.height, pixelSize.current, pixelSpace.current)
    indexCalculator.current = new IndexCalculator({ xPositions: xPositions.current, yPositions: yPositions.current })
    setSettings((prevSettings) => ({
      ...prevSettings,
      pixelSize: pixelSize.current,
    }))
    setCanvasIsUpdated(true)
  }, [settings.width, settings.height, setSettings])

  // Update canvas
  React.useEffect(() => {
    if (!canvasIsUpdated) return
    if (mode.current !== ModeType.INIT && mode.current !== ModeType.RESIZE) {
      return
    }
    setSettings((prevSettings) => {
      const newPixels = generatePixels(prevSettings.pixels)
      const newSettings = {
        ...prevSettings,
        pixels: newPixels,
        mode: ModeType.STANDBY,
      }
      return addHistory(newSettings)
    })
    setCanvasIsUpdated(false)
  }, [canvasIsUpdated, setSettings, generatePixels, setCanvasIsUpdated])

  // Undo / Redo
  React.useEffect(() => {
    if (mode.current !== ModeType.UNDO && mode.current !== ModeType.REDO) {
      return
    }
    setSettings((prevSettings) => {
      if (
        prevSettings.history[prevSettings.historyPointer] === undefined ||
        (prevSettings.historyPointer === 0 && prevSettings.history.length === 1)
      ) {
        return prevSettings
      }
      const historyData = prevSettings.history[prevSettings.historyPointer]
      pixelSize.current = calcPixelSize(historyData.width)
      xPositions.current = calcPositions(historyData.width, historyData.pixelSize, pixelSpace.current)
      yPositions.current = calcPositions(historyData.height, historyData.pixelSize, pixelSpace.current)
      indexCalculator.current = new IndexCalculator({ xPositions: xPositions.current, yPositions: yPositions.current })
      const prevPixels = textToPixels(historyData.pixels, xPositions.current, yPositions.current)
      return {
        ...prevSettings,
        width: historyData.width,
        height: historyData.height,
        pixelSize: pixelSize.current,
        pixels: generatePixels(prevPixels),
      }
    })
  }, [settings.historyPointer, generatePixels, setSettings])

  // Move
  React.useEffect(() => {
    if (
      mode.current !== ModeType.MOVE_UP &&
      mode.current !== ModeType.MOVE_DOWN &&
      mode.current !== ModeType.MOVE_LEFT &&
      mode.current !== ModeType.MOVE_RIGHT
    ) {
      return
    }
    setSettings((prevSettings) =>
      addHistory({
        ...prevSettings,
        pixels: movePixels(mode.current, prevSettings.pixels, xPositions.current, yPositions.current),
        mode: ModeType.STANDBY,
      })
    )
  }, [settings.mode, setSettings])

  // Initialize pixel size
  React.useEffect(() => {
    if (mode.current !== ModeType.INIT) {
      return
    }
    pixelSize.current = calcPixelSize(settings.width)
    if (!initialPixelSize.current) {
      initialPixelSize.current = pixelSize.current
    }
    setSettings((prevSettings) => ({
      ...prevSettings,
      pixelSize: pixelSize.current,
    }))
    xPositions.current = calcPositions(settings.width, pixelSize.current, pixelSpace.current)
    yPositions.current = calcPositions(settings.height, pixelSize.current, pixelSpace.current)
    indexCalculator.current = new IndexCalculator({ xPositions: xPositions.current, yPositions: yPositions.current })
    setCanvasIsUpdated(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Event handling
  const isDrawing = React.useRef(false)
  const drawPixel = React.useCallback(
    (e: Konva.KonvaEventObject<MouseEvent | TouchEvent>) => {
      const stage = e.target.getStage() as Konva.Stage
      const pos = stage.getPointerPosition() as {
        x: number
        y: number
      }

      const yIndex = indexCalculator.current.fromY(pos.y)
      const xIndex = indexCalculator.current.fromX(pos.x)

      setSettings((prevSettings) => {
        const newPixels = [...prevSettings.pixels]
        if (
          prevSettings.pixels[yIndex] !== undefined &&
          prevSettings.pixels[yIndex][xIndex] !== undefined &&
          prevSettings.pixels[yIndex][xIndex].value !== settings.selectedEmoji
        ) {
          newPixels[yIndex][xIndex] = {
            ...newPixels[yIndex][xIndex],
            value: settings.selectedEmoji,
          }
        }
        return {
          ...prevSettings,
          pixels: newPixels,
        }
      })
    },
    [settings.selectedEmoji, setSettings]
  )
  const eventHander = React.useMemo(
    () => ({
      onDrawStart: (e: Konva.KonvaEventObject<MouseEvent | TouchEvent>) => {
        isDrawing.current = true
        setSettings((prevSettings) => ({
          ...prevSettings,
          mode: ModeType.DRAW,
        }))
        drawPixel(e)
      },
      onDrawMove: (e: Konva.KonvaEventObject<MouseEvent | TouchEvent>) => {
        if (!isDrawing.current) {
          return
        }
        drawPixel(e)
      },
      onDrawStop: () => {
        isDrawing.current = false
        setSettings((prevSettings) => ({
          ...prevSettings,
          mode: ModeType.STANDBY,
        }))
        setSettings((prevSettings) => addHistory(prevSettings))
      },
      onMouseLeave: () => {
        isDrawing.current = false
        setSettings((prevSettings) => ({
          ...prevSettings,
          mode: ModeType.STANDBY,
        }))
      },
    }),
    [drawPixel, setSettings]
  )

  return (
    <Box ref={rootRef} sx={{ display: 'flex', justifyContent: 'center' }}>
      <Box
        sx={{
          flexShrink: 0,
          width: canvasWidth,
          height: canvasHeight,
          backgroundColor: 'white',
          borderRadius: '3px',
        }}
      >
        <Stage
          width={canvasWidth}
          height={canvasHeight}
          onMouseDown={eventHander.onDrawStart}
          onMousemove={eventHander.onDrawMove}
          onMouseup={eventHander.onDrawStop}
          onMouseLeave={eventHander.onMouseLeave}
          onTouchStart={eventHander.onDrawStart}
          onTouchMove={eventHander.onDrawMove}
          onTouchEnd={eventHander.onDrawStop}
        >
          <Layer>
            {settings.pixels.map((line) =>
              line.map((px) => {
                const [x, y] = [px.position.x, px.position.y]
                return <Pixel key={`${y}_${x}`} data={px} pixelSize={settings.pixelSize} />
              })
            )}
          </Layer>
        </Stage>
      </Box>
    </Box>
  )
}
