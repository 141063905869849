import Twemoji from 'twemoji'
import runes from 'runes'
import { CustomEmoji } from 'emoji-mart'

const twemojiUrls = {} as {
  [emoji: string]: string
}
export function toTwemojiUrl(emoji: string): string {
  if (twemojiUrls[emoji] !== undefined) {
    return twemojiUrls[emoji]
  }
  const div = document.createElement('div')
  div.textContent = emoji
  Twemoji.parse(div)
  const img: HTMLImageElement | null = div.querySelector('img')
  if (img === null) {
    return ''
  }
  twemojiUrls[emoji] = img.src
  return img.src
}

export const basicEmojis = ['⬛', '⬜', '🟥', '🟧', '🟨', '🟩', '🟦', '🟪', '🟫']

export const CustomEmojiCategoryType = {
  SKIN_TONE: 'Skin Tone',
} as const
export type CustomEmojiCategoryType = typeof CustomEmojiCategoryType[keyof typeof CustomEmojiCategoryType]

function unicodeToEmoji(unicode: string): string {
  const codepoint = parseInt(unicode, 16)
  return String.fromCodePoint(codepoint)
}

interface CustomEmojiConfig {
  name: string
  native: string
  keywords: string[]
  category: CustomEmojiCategoryType
}

const customEmojiConfigs = {
  light_skin_tone: {
    name: 'Light Skin Tone',
    native: unicodeToEmoji('1f3fb'),
    keywords: ['skin tone', 'square'],
    category: CustomEmojiCategoryType.SKIN_TONE,
  },
  medium_light_skin_tone: {
    name: 'Medium Light Skin Tone',
    native: unicodeToEmoji('1f3fc'),
    keywords: ['skin tone', 'square'],
    category: CustomEmojiCategoryType.SKIN_TONE,
  },
  medium_skin_tone: {
    name: 'Medium Skin Tone',
    native: unicodeToEmoji('1f3fd'),
    keywords: ['skin tone', 'square'],
    category: CustomEmojiCategoryType.SKIN_TONE,
  },
  medium_dark_skin_tone: {
    name: 'Medium Dark Skin Tone',
    native: unicodeToEmoji('1f3fe'),
    keywords: ['skin tone', 'square'],
    category: CustomEmojiCategoryType.SKIN_TONE,
  },
  dark_skin_tone: {
    name: 'Dark Skin Tone',
    native: unicodeToEmoji('1f3ff'),
    keywords: ['skin tone', 'square'],
    category: CustomEmojiCategoryType.SKIN_TONE,
  },
} as {
  [id: string]: CustomEmojiConfig
}

export const customEmojis = Object.entries(customEmojiConfigs).map(([id, config]) => ({
  name: config.name,
  short_names: [id],
  emoticons: [],
  keywords: config.keywords,
  imageUrl: toTwemojiUrl(config.native),
  customCategory: config.category,
})) as unknown as CustomEmoji[]

export function getCustomEmojiNativeValueById(id: string): string {
  return customEmojiConfigs[id].native
}

const skinToneEmojis = ['1f3fb', '1f3fc', '1f3fd', '1f3fe', '1f3ff'].map((unicode) => unicodeToEmoji(unicode))
const doubleModifierRegex = new RegExp(
  `(${skinToneEmojis
    .map((skinToneEmoji) => skinToneEmojis.map((anotherSkinToneEmoji) => skinToneEmoji + anotherSkinToneEmoji))
    .flat()
    .join('|')})`,
  'ig'
)

export function splitEmojis(emojiLine: string): string[] {
  // Skin tone emojis cannot be split correctly, therefore they need to be corrected
  const modifierMatches = emojiLine.match(doubleModifierRegex)
  const correctedEmojiLine = !modifierMatches
    ? emojiLine
    : modifierMatches.reduce((line, v) => {
        const skinTone0 = unicodeToEmoji((v.codePointAt(0) as number).toString(16))
        const skinTone1 = unicodeToEmoji((v.codePointAt(2) as number).toString(16))
        return line.replace(v, skinTone0 + skinTone0 + skinTone1 + skinTone1)
      }, emojiLine)
  return runes(correctedEmojiLine).map((emoji: string): string => {
    if (emoji.match(doubleModifierRegex)) {
      const codepoint0 = emoji.codePointAt(0) as number
      return unicodeToEmoji(codepoint0.toString(16))
    }
    return emoji
  })
}
