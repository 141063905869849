import { memo } from 'react'
import styled from '@emotion/styled'
import twemoji from 'twemoji'

const TwemojiSpan = styled.span`
  .emoji {
    display: inline-block;
    width: auto;
    height: 1em;
    vertical-align: -0.125em;
  }
`

function Twemoji({ emoji }: { emoji: string }) {
  return (
    <TwemojiSpan
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: twemoji.parse(emoji, {
          folder: 'svg',
          ext: '.svg',
        }),
      }}
    />
  )
}

export default memo(Twemoji)
