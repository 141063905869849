import React from 'react'

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions'
import Modal from '@mui/material/Modal'
import 'emoji-mart/css/emoji-mart.css'
import { Picker, EmojiData } from 'emoji-mart'

import { theme } from 'utils/styles'
import { toTwemojiUrl, basicEmojis, customEmojis, getCustomEmojiNativeValueById } from 'utils/emoji'

import { SettingsContext } from './Emoxel'

export default function Palette() {
  const { settings, setSettings } = React.useContext(SettingsContext)
  const [modalOpen, setModalOpen] = React.useState(false)
  const [isAdvanced, setIsAdvanced] = React.useState(false)

  const rootRef = React.useRef<HTMLElement>(null)
  
  const eventHandler = React.useMemo(
    () => ({
      onClickEmoji: (emoji: EmojiData) => {
        const emojiData = (emoji as unknown as { id: string, native: string, custom: boolean})
        const emojiValue = emojiData.custom ? getCustomEmojiNativeValueById(emojiData.id) : emojiData.native
        setSettings((prevSettings) => ({
          ...prevSettings,
          selectedEmoji: emojiValue,
        }))
        setIsAdvanced(!basicEmojis.includes(emojiValue))
        eventHandler.modalClose()
      },
      modalOpen: () => {
        setModalOpen(true)
      },
      modalClose: () => {
        setModalOpen(false)
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setModalOpen, setIsAdvanced, setSettings]
  )

  return (
    <Box ref={rootRef} sx={{ display: 'flex', justifyContent: 'center' }}>
      <Grid sx={{ flexGrow: 1, marginTop: 2, marginBottom: 2 }} container>
        <Grid container justifyContent="center" spacing={0}>
          {basicEmojis.map((value) => (
            <Grid key={value} item>
              <Button
                size="medium"
                color="primary"
                sx={{ minWidth: 0, padding: '7px' }}
                variant={value === settings.selectedEmoji ? 'contained' : undefined}
                onClick={() => {
                  setSettings((prevSettings) => ({
                    ...prevSettings,
                    selectedEmoji: value,
                  }))
                  setIsAdvanced(false)
                }}
              >
                <img src={toTwemojiUrl(value)} alt={value} width={16} height={16} />
              </Button>
            </Grid>
          ))}
          <Grid item>
            <Button
              size="medium"
              color="primary"
              startIcon={<EmojiEmotionsIcon sx={{ width: '14px', height: '12px' }} />}
              sx={{ minWidth: 0, padding: '7px', marginLeft: '7px' }}
              variant={isAdvanced ? 'contained' : 'outlined'}
              onClick={eventHandler.modalOpen}
            >
              <img src={toTwemojiUrl(settings.selectedEmoji)} alt={settings.selectedEmoji} width={13} height={13} />
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Modal
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        open={modalOpen}
        onClose={eventHandler.modalClose}
        aria-labelledby="modal_emoji_picker"
        disableAutoFocus
        disablePortal
      >
        <Box
          id="modal_emoji_picker"
          sx={{
            width: rootRef.current && rootRef.current.clientWidth * 0.9,
            bgcolor: 'background.paper',
            border: `2px solid ${theme.palette.background.paper}`,
            borderRadius: '3px',
            boxShadow: 24,
            '.emoji-mart': { fontFamily: theme.typography.fontFamily },
            'input::-webkit-input-placeholder': { fontFamily: theme.typography.fontFamily },
            'input:-moz-placeholder': { fontFamily: theme.typography.fontFamily },
            'input::-moz-placeholder': { fontFamily: theme.typography.fontFamily },
            'input:-ms-input-placeholder': { fontFamily: theme.typography.fontFamily },
            '.emoji-mart-category-label span': {
              backgroundColor: theme.palette.background.paper,
            },
            '.emoji-mart-category .emoji-mart-emoji:hover:before': {
              borderRadius: '4px',
            },
            '.emoji-mart-dark .emoji-mart-search input': {
              border: 'none',
            },
            '.emoji-mart-title-label': {
              fontSize: '1em',
            },
          }}
        >
          <Picker
            theme="dark"
            title="Please select"
            emoji="art"
            set="twitter"
            custom={customEmojis}
            color={theme.palette.primary.main}
            style={{
              width: '100%',
              backgroundColor: theme.palette.background.paper,
              borderColor: theme.palette.background.paper,
            }}
            onClick={eventHandler.onClickEmoji}
          />
        </Box>
      </Modal>
    </Box>
  )
}
