import React from 'react'

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'

// import FormGroup from '@mui/material/FormGroup'
// import FormControlLabel from '@mui/material/FormControlLabel'
// import Checkbox from '@mui/material/Checkbox'

import Stack from '@mui/material/Stack'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import UndoIcon from '@mui/icons-material/Undo'
import RedoIcon from '@mui/icons-material/Redo'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'

import Select, { SelectChangeEvent } from '@mui/material/Select'

import { range, deepClone } from 'utils/ArrayHelper'
import SizeCalculator from 'utils/MaxSizeCalculator'
import { addHistory, UserSettings, PixelData, ModeType } from 'utils/emoxel'
import { SettingsContext } from './Emoxel'

const config = {
  maxPixels: {
    small: 11 * 11,
    large: 32 * 32,
  },
}

export default function Settings() {
  const { settings, setSettings } = React.useContext(SettingsContext)
  // const [maxPixels, setMaxPixels] = React.useState(config.maxPixels.small)
  // const [sizeCalculator, setSizeCalculator] = React.useState(new SizeCalculator(config.maxPixels.small))
  // React.useEffect(() => {
  //   setSizeCalculator(new SizeCalculator(maxPixels))
  // }, [maxPixels])

  const sizeCalculator = React.useRef(new SizeCalculator(config.maxPixels.small))

  React.useEffect(() => {
    const maxHeight = sizeCalculator.current.calcMaxSize(settings.height)
    const newWidthOptions = generateSizeOptions(maxHeight)
    setWidthOptions(newWidthOptions)
  }, [settings.height])

  React.useEffect(() => {
    const maxWidth = sizeCalculator.current.calcMaxSize(settings.width)
    const newHeightOptions = generateSizeOptions(maxWidth)
    setHeightOptions(newHeightOptions)
  }, [settings.width])

  const generateSizeOptions = (max: number): Array<number> => range(1, max)

  const defaultSizeOptions = generateSizeOptions(11)
  const [widthOptions, setWidthOptions] = React.useState([...defaultSizeOptions])
  const [heightOptions, setHeightOptions] = React.useState([...defaultSizeOptions])

  const eventHandler = React.useMemo(
    () => ({
      updateWidth: (e: SelectChangeEvent) => {
        const selectedWidth = parseInt(e.target.value, 10)
        setSettings((data: UserSettings) => ({
          ...data,
          width: selectedWidth,
          mode: ModeType.RESIZE,
        }))
      },
      updateHeight: (e: SelectChangeEvent) => {
        const selectedHeight = parseInt(e.target.value, 10)
        setSettings((data: UserSettings) => ({
          ...data,
          height: selectedHeight,
          mode: ModeType.RESIZE,
        }))
      },
      // updateDirectTweet: (e: React.ChangeEvent<HTMLInputElement>) => {
      //   setSettings((data: UserSettings) => ({ ...data, directTweet: e.target.checked }))
      //   if (e.target.checked) {
      //     setSettings((data: UserSettings) => ({
      //       ...data,
      //       width: defaultSettings.width,
      //       height: defaultSettings.height,
      //     }))
      //     setMaxPixels(config.maxPixels.small)
      //   } else {
      //     setMaxPixels(config.maxPixels.large)
      //   }
      // },
      clearAll: () => {
        setSettings((prevSettings) => {
          // Deep copy is required
          const prevPixels = deepClone(prevSettings.pixels) as PixelData[][]
          const clearedPixels = prevPixels.map((line) =>
            line.map((pixel) => ({
              ...pixel,
              value: '⬜',
            }))
          )
          return addHistory({
            ...prevSettings,
            pixels: clearedPixels,
          })
        })
      },
      undo: () => {
        // empty history
        if (settings.history.length === 0) {
          return
        }
        const prevPointer = settings.historyPointer + 1
        // last history
        if (settings.history[prevPointer] === undefined) {
          return
        }
        setSettings((prevSettings) => ({
          ...prevSettings,
          mode: ModeType.UNDO,
          historyPointer: prevPointer,
        }))
      },
      redo: () => {
        // empty history
        if (settings.history.length === 0) {
          return
        }
        const nextPointer = settings.historyPointer - 1
        // latest history
        if (settings.history[nextPointer] === undefined) {
          return
        }
        setSettings((prevSettings) => ({
          ...prevSettings,
          mode: ModeType.REDO,
          historyPointer: nextPointer,
        }))
      },
      moveUp: () => {
        setSettings((prevSettings) => ({ ...prevSettings, mode: ModeType.MOVE_UP }))
      },
      moveDown: () => {
        setSettings((prevSettings) => ({ ...prevSettings, mode: ModeType.MOVE_DOWN }))
      },
      moveLeft: () => {
        setSettings((prevSettings) => ({ ...prevSettings, mode: ModeType.MOVE_LEFT }))
      },
      moveRight: () => {
        setSettings((prevSettings) => ({ ...prevSettings, mode: ModeType.MOVE_RIGHT }))
      },
    }),
    [settings.history, settings.historyPointer, setSettings]
  )

  return (
    <Box>
      <Grid container direction="row" spacing={0}>
        <Grid container justifyContent="center" mb="12px">
          <Stack direction="row" spacing={1.5}>
            <IconButton color="primary" aria-label="moveLeft" onClick={eventHandler.moveLeft}>
              <ArrowLeftIcon />
            </IconButton>
            <IconButton color="primary" aria-label="moveUp" onClick={eventHandler.moveUp}>
              <ArrowDropUpIcon />
            </IconButton>
            <IconButton color="primary" aria-label="moveDown" onClick={eventHandler.moveDown}>
              <ArrowDropDownIcon />
            </IconButton>
            <IconButton color="primary" aria-label="moveRight" onClick={eventHandler.moveRight}>
              <ArrowRightIcon />
            </IconButton>
            <IconButton color="primary" aria-label="undo" onClick={eventHandler.undo}>
              <UndoIcon />
            </IconButton>
            <IconButton color="primary" aria-label="redo" onClick={eventHandler.redo}>
              <RedoIcon />
            </IconButton>
          </Stack>
        </Grid>
        <Grid container justifyContent="center" alignItems="center">
          <Grid item>
            <FormControl sx={{ m: 1 }}>
              <InputLabel id="emoxel_settings_width">Width</InputLabel>
              <Select
                labelId="emoxel_settings_width"
                value={`${settings.width}`}
                label="Width"
                sx={{ height: '40px', width: '90px' }}
                onChange={eventHandler.updateWidth}
              >
                {widthOptions.map((v: number) => (
                  <MenuItem key={`width${v}`} value={`${v}`}>
                    {v}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl sx={{ m: 1 }}>
              <InputLabel id="emoxel_settings_height">Height</InputLabel>
              <Select
                labelId="emoxel_settings_height"
                value={`${settings.height}`}
                label="Height"
                sx={{ height: '40px', width: '90px' }}
                onChange={eventHandler.updateHeight}
              >
                {heightOptions.map((v: number) => (
                  <MenuItem key={`height${v}`} value={`${v}`}>
                    {v}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid>
            <IconButton color="warning" aria-label="delete" onClick={eventHandler.clearAll}>
              <DeleteIcon />
            </IconButton>
          </Grid>
        </Grid>
        {/* <FormGroup>
        <FormControlLabel
          control={<Checkbox defaultChecked onChange={eventHandler.updateDirectTweet} />}
          label="Small"
        />
      </FormGroup> */}
      </Grid>
    </Box>
  )
}
