export default class MaxSizeCalculator {
  maxPixels: number

  constructor(maxPixels: number) {
    this.maxPixels = maxPixels
  }

  calcMaxSize(size: number) {
    const calculatedSize = Math.ceil(this.maxPixels / size)
    if (this.maxPixels % size > 0) {
      return calculatedSize - 1
    }
    return calculatedSize
  }
}
