import React from 'react'
import { splitEmojis } from 'utils/emoji'
import { range, deepClone } from 'utils/ArrayHelper'

export interface Position {
  x: number
  y: number
}

export interface PixelData {
  value: string
  position: Position
}

export interface HistoryData {
  pixels: string
  width: number
  height: number
  pixelSize: number
}

export const ModeType = {
  INIT: 'init',
  STANDBY: 'standby',
  DRAW: 'draw',
  UNDO: 'undo',
  REDO: 'redo',
  RESIZE: 'resize',
  MOVE_UP: 'moveUp',
  MOVE_DOWN: 'moveDown',
  MOVE_LEFT: 'moveLeft',
  MOVE_RIGHT: 'moveRight',
} as const
export type ModeType = typeof ModeType[keyof typeof ModeType]

export interface UserSettings {
  directTweet: boolean
  width: number
  height: number
  selectedEmoji: string
  pixels: PixelData[][]
  mode: ModeType
  history: HistoryData[]
  historyPointer: number
  pixelSize: number
}

export interface SettingsContext {
  settings: UserSettings
  setSettings: React.Dispatch<React.SetStateAction<UserSettings>>
}

const defaultWidth = 11
const defaultHeight = 11
const defaultSelectedEmoji = '⬛'
export const defaultSettings: UserSettings = {
  directTweet: true,
  width: defaultWidth,
  height: defaultHeight,
  selectedEmoji: defaultSelectedEmoji,
  mode: ModeType.INIT,
  pixels: [],
  history: [],
  historyPointer: 0,
  pixelSize: 0,
}

export const useCalculatedPositions = () =>
  React.useCallback(
    (length: number, pixelSize: number, space: number): number[] =>
      range(1, length).map((pixelPos) => (pixelPos - 1) * (pixelSize + space) + space),
    []
  )
export function calcPositions(length: number, pixelSize: number, space: number): number[] {
  return range(1, length).map((pixelPos) => (pixelPos - 1) * (pixelSize + space) + space)
}

export function textToPixels(text: string, xPositions: number[], yPositions: number[]): PixelData[][] {
  return text.split('\n').map((line: string, yIndex) =>
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    splitEmojis(line).map((emoji, xIndex) => ({
      value: emoji,
      position: {
        x: xPositions[xIndex],
        y: yPositions[yIndex],
      },
    }))
  )
}

export function pixelsToText(pixels: PixelData[][]): string {
  return pixels.map((line) => line.map((pixel) => pixel.value).join('')).join('\n')
}

export function movePixels(
  mode: ModeType,
  pixels: PixelData[][],
  xPositions: number[],
  yPositions: number[]
): PixelData[][] {
  const pixelValues = pixelsToText(pixels)
    .split('\n')
    .map((line) => splitEmojis(line))
  let movedPixelValues = [] as string[][]
  switch (mode) {
    case ModeType.MOVE_UP:
      movedPixelValues = [...pixelValues.slice(1), range(0, xPositions.length - 1).map(() => '⬜')]
      break
    case ModeType.MOVE_DOWN:
      movedPixelValues = [
        range(0, xPositions.length - 1).map(() => '⬜'),
        ...pixelValues.slice(0, pixelValues.length - 1),
      ]
      break
    case ModeType.MOVE_RIGHT:
      movedPixelValues = pixelValues.map((line) => ['⬜', ...line.slice(0, line.length - 1)])
      break
    case ModeType.MOVE_LEFT:
      movedPixelValues = pixelValues.map((line) => [...line.slice(1), '⬜'])
      break
    default:
      movedPixelValues = pixelValues
      break
  }
  return textToPixels(movedPixelValues.map((line) => line.join('')).join('\n'), xPositions, yPositions)
}

export function addHistory(settings: UserSettings): UserSettings {
  const prevHistory = deepClone(settings.history) as HistoryData[]

  const newHistory = prevHistory.slice(settings.historyPointer)
  newHistory.unshift({
    pixelSize: settings.pixelSize,
    width: settings.width,
    height: settings.height,
    pixels: pixelsToText(settings.pixels),
  })
  // console.log(prevSettings.historyPointer, newHistory)
  return {
    ...settings,
    history: newHistory.slice(0, 30),
    historyPointer: 0,
  }
}
