import './App.css'

import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import Box from '@mui/material/Box'

import { Helmet, HelmetProvider } from 'react-helmet-async'
import Header from 'components/layout/Header'
import Footer from 'components/layout/Footer'
import Content from 'components/layout/Content'
import Emoxel from 'components/editor/Emoxel'

import { siteConfig as site } from 'utils/site'
import { theme } from 'utils/styles'

function App() {
  return (
    <HelmetProvider>
      <Helmet>
        <script async src={`https://www.googletagmanager.com/gtag/js?id=${site.gaTrackingId}`} />
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${site.gaTrackingId}');
          `}
        </script>
      </Helmet>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Box className="App" sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
          <Header />
          <Content>
            <Emoxel />
          </Content>
          <Footer />
        </Box>
      </ThemeProvider>
    </HelmetProvider>
  )
}

export default App
