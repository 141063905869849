export class InfoModal {
  private static isDisplayedFirstKey = 'emoxel.infoModal.isDisplayedFirst'

  public static getIsDisplayedFirst(): boolean {
    const shouldDisplay = localStorage.getItem(InfoModal.isDisplayedFirstKey)
    return shouldDisplay !== '1'
  }

  public static setIsDisplayedFirst(isDisplayedFirst: boolean): void {
    localStorage.setItem(InfoModal.isDisplayedFirstKey, isDisplayedFirst ? '1' : '0')
  }
}
