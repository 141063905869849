import { ReactNode } from 'react'
import CssBaseline from '@mui/material/CssBaseline'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'

export default function Content({ children }: { children: ReactNode }) {
  return (
    <>
      <CssBaseline />
      <Container maxWidth="sm" sx={{ mt: '15px' }}>
        <Box sx={{ height: '100%' }}>{children}</Box>
      </Container>
    </>
  )
}
